import { ReactNode } from "react";
import { useDrag } from "react-dnd";
import { useSelector } from "react-redux";
import { IRootState } from "state/types";
import { ItemTypes } from "../../../types/Color";
import { clipTop } from "../../../utils";
import { selectColor, selectPickerHeight } from "../state/selectors";
import "./ColorSelector.css";

interface ColorSelectorProps {
  id: any;
  children?: ReactNode;
}

export const ColorSelector: React.FC<ColorSelectorProps> = ({ id }) => {
  const pickerHeight = useSelector(selectPickerHeight);
  const { x, y } = useSelector((state: IRootState) => selectColor(state, id));
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: ItemTypes.COLORSELECTOR,
      item: { id, x, y },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [id, x, y]
  );

  if (isDragging) {
    return <div ref={drag} />;
  }
  return (
    <div
      className="cp-hue-indicator"
      ref={drag}
      style={{
        backgroundColor: "transparent",
        left: x,
        top: clipTop(y, pickerHeight),
      }}
    />
  );
};
