import { ColorSwatch } from "./ColorSwatch/ColorSwatch";
import "./ColorPalette.css";
import { Color } from "types/Color";

interface ColorPaletteProps {
  colorsSorted: Color[];
}

export const ColorPalette = (props: ColorPaletteProps) => {
  const { colorsSorted } = props;
  return (
    <div className="cp-parent">
      <div
        className="cp-scale"
        style={{
          backgroundImage: `
    linear-gradient(
      to right,
      ${colorsSorted[0].hex},
      ${colorsSorted[1].hex},
      ${colorsSorted[2].hex}
    )`,
        }}
      ></div>
      <div className="cp-wrapper">
        {colorsSorted.map((color, ind) => {
          return <ColorSwatch key={ind} color={color} />;
        })}
      </div>
    </div>
  );
};
