import { useDrop, XYCoord } from "react-dnd";
import { useDispatch, useSelector } from "react-redux";
import { DragItem, ItemTypes } from "../../../types/Color";
import { setHueOffset } from "../state/actions";
import { selectHueStart, selectPickerWidth } from "../state/selectors";
import "./ColorPickerWrapper.css";

export interface ColorPickerWrapperProps {
  children?: React.ReactNode;
}
export const ColorPickerWrapper: React.FC<ColorPickerWrapperProps> = (
  props: ColorPickerWrapperProps
) => {
  const dispatch = useDispatch();
  const hueStart = useSelector(selectHueStart);
  const pickerWidth = useSelector(selectPickerWidth);
  const [, drop] = useDrop(
    () => ({
      accept: ItemTypes.COLORRANGE,
      drop(item: DragItem, monitor) {
        const delta = monitor.getDifferenceFromInitialOffset() as XYCoord;
        const hueDelta = (delta.x / pickerWidth) * 360;
        let left: number = Math.round(hueStart - hueDelta);
        left = left < 0 ? 360 + left : left > 360 ? left - 360 : left;
        dispatch(setHueOffset(left));
        return undefined;
      },
      hover(item: DragItem, monitor) {
        const delta = monitor.getDifferenceFromInitialOffset() as XYCoord;
        const hueDelta = (delta.x / pickerWidth) * 360;
        let left: number = Math.round(hueStart - hueDelta);
        left = left < 0 ? 360 + left : left > 360 ? left - 360 : left;
        dispatch(setHueOffset(left));
        return undefined;
      },
    }),
    []
  );
  return (
    <div ref={drop} className="cp-parent-wrapper">
      {props.children}
    </div>
  );
};
