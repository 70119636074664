import { IGalleryState } from "./types";
import * as GalleryActions from "./actions";
import { ActionType, getType } from "typesafe-actions";

export const initialState: IGalleryState = {
  palettes: [],
};

export const galleryReducer = (
  state: IGalleryState = initialState,
  action: ActionType<typeof GalleryActions>
): IGalleryState => {
  switch (action.type) {
    case getType(GalleryActions.addPaletteToGallery):
      return { ...state, palettes: [{ ...action.payload }, ...state.palettes] };
    case getType(GalleryActions.removePaletteFromGallery):
      return {
        ...state,
        palettes: [...state.palettes.filter((_, i) => i !== action.payload)],
      };
    default:
      return state;
  }
};
