import { useEffect, useRef } from "react";
import { DragItem, ItemTypes } from "../../types/Color";
import { generateHueRangeFromStart } from "../../utils";
import "./ColorPicker.css";
import { ColorSelector } from "./ColorSelector/ColorSelector";
import { SaturationSlider } from "./SaturationSlider/SaturationSlider";
import { useDrag, useDrop, XYCoord } from "react-dnd";
import useResizeObserver from "use-resize-observer";
import { mergeRefs } from "react-merge-refs";
import { getEmptyImage } from "react-dnd-html5-backend";
import {
  selectColorScheme,
  selectHueStart,
  selectSaturation,
} from "./state/selectors";
import { useSelector, useDispatch } from "react-redux";
import {
  setColorInScheme,
  setPickerWidth,
  setPickerHeight,
  setPickerTop,
} from "./state/actions";
import { ColorCoords } from "components/ColorPicker/state/types";

export const ColorPicker: React.FC = () => {
  const dispatch = useDispatch();
  const hueStart = useSelector(selectHueStart);
  const colors = useSelector(selectColorScheme);
  const saturation = useSelector(selectSaturation);
  const moveColorPicker = (id: string, left: number, top: number) => {
    dispatch(setColorInScheme({ x: left, y: top } as ColorCoords, id));
  };

  const [, drop] = useDrop(
    () => ({
      accept: ItemTypes.COLORSELECTOR,
      drop(item: DragItem, monitor) {
        const delta = monitor.getDifferenceFromInitialOffset() as XYCoord;
        const left = Math.round(item.x + delta.x);
        const top = Math.round(item.y + delta.y);
        moveColorPicker(item.id, left, top);
        return undefined;
      },
      hover(item, monitor) {
        const delta = monitor.getDifferenceFromInitialOffset() as XYCoord;
        const left = Math.round(item.x + delta.x);
        const top = Math.round(item.y + delta.y);
        moveColorPicker(item.id, left, top);
        return undefined;
      },
    }),
    [moveColorPicker]
  );
  const [, drag, preview] = useDrag(
    () => ({
      type: ItemTypes.COLORRANGE,
      item: { hueStart },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [hueStart]
  );
  useEffect(() => {
    preview(getEmptyImage());
  }, [preview]);
  const { ref, width, height } = useResizeObserver<HTMLDivElement>();
  const locRef = useRef(document.createElement("div"));
  const mergedRef = mergeRefs([ref, drop, locRef, drag]);
  useEffect(() => {
    //@ts-ignore
    dispatch(setPickerWidth(width !== undefined ? width : 100));
  }, [width, dispatch]);
  useEffect(() => {
    //@ts-ignore
    dispatch(setPickerHeight(height !== undefined ? height : 100));
  }, [height, dispatch]);
  useEffect(() => {
    dispatch(setPickerTop(locRef.current.getBoundingClientRect().top));
  }, [dispatch]);
  return (
    <div className="cp-free-root">
      <div
        ref={mergedRef}
        className="cp-hue"
        style={{
          backgroundColor: `rgba(255, 255,255, ${saturation})`,
          backgroundImage: `linear-gradient(white, transparent, black),
          linear-gradient(
            to right,
            ${generateHueRangeFromStart(hueStart).join(",")}
          )`,
        }}
      >
        {Object.keys(colors).map((key) => {
          return <ColorSelector id={key} key={key} />;
        })}
      </div>
      <SaturationSlider />
    </div>
  );
};
