import { Color } from "types/Color";
import "./ColorSwatch.css";

interface ColorSwatchProps {
  color: Color;
}

export const ColorSwatch = (props: ColorSwatchProps) => {
  return (
    <div
      className="cs-wrapper"
      style={{
        backgroundColor: props.color.hex,
      }}
    ></div>
  );
};
