import "./App.css";
import { AppBar, Toolbar, Typography } from "@mui/material";
import { Main } from "components/Main/Main";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd/dist/core";
function App() {
  return (
    <div className="App">
      <AppBar position="fixed">
        <Toolbar>
          <Typography>Helpful Color Scheme Picker Tool</Typography>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <DndProvider backend={HTML5Backend}>
        <Main />
      </DndProvider>
    </div>
  );
}

export default App;
