import { IRootState } from "state/types";
import { getColorFromCoordsWithHueOffset } from "utils";
import { ColorScheme, ColorCoords } from "./types";
import { Color, ColorValueScheme } from "types/Color";

export const selectHueStart = (state: IRootState): number =>
  state.colorPicker.hueStart;
export const selectColorScheme = (state: IRootState): ColorScheme =>
  state.colorPicker.colorScheme;
export const selectColor = (
  state: IRootState,
  color: "first" | "midtone" | "second"
): ColorCoords => state.colorPicker.colorScheme[color];
export const selectPickerWidth = (state: IRootState): number =>
  state.colorPicker.pickerWidth;
export const selectPickerHeight = (state: IRootState): number =>
  state.colorPicker.pickerHeight;
export const selectPickerTop = (state: IRootState): number =>
  state.colorPicker.pickerTop;
export const selectSaturation = (state: IRootState): number =>
  state.colorPicker.saturation;

const statefulGetColor = (
  state: IRootState,
  color: "first" | "midtone" | "second"
): Color =>
  getColorFromCoordsWithHueOffset(
    state.colorPicker.colorScheme[color],
    state.colorPicker.hueStart,
    state.colorPicker.saturation,
    state.colorPicker.pickerWidth
  );
export const selectColorValue = (
  state: IRootState,
  color: "first" | "midtone" | "second"
): Color => statefulGetColor(state, color);
export const selectColorValueScheme = (
  state: IRootState
): ColorValueScheme => ({
  first: statefulGetColor(state, "first"),
  midtone: statefulGetColor(state, "midtone"),
  second: statefulGetColor(state, "second"),
});
