import { ColorCoords, ColorScheme } from "./types";

const handleColorPickerOutOfBounds = (left: number, pickerWidth: number) => {
  return left > pickerWidth
    ? left - pickerWidth
    : left < 0
    ? pickerWidth + left
    : left;
};

export const moveSinglePicker = (
  id: string,
  newCoords: ColorCoords,
  currentScheme: ColorScheme,
  pickerWidth: number
): ColorScheme => {
  let newColors: ColorScheme = {
    first: { ...currentScheme.first },
    midtone: { ...currentScheme.midtone },
    second: { ...currentScheme.second },
  };
  newColors[id] = newCoords;
  if (id === "midtone") {
    newColors.first.x += newCoords.x - currentScheme.midtone.x;
    newColors.first.x = handleColorPickerOutOfBounds(
      newColors.first.x,
      pickerWidth
    );
    newColors.first.y += newCoords.y - currentScheme.midtone.y;
    newColors.second.x += newCoords.x - currentScheme.midtone.x;
    newColors.second.x = handleColorPickerOutOfBounds(
      newColors.second.x,
      pickerWidth
    );
    newColors.second.y += newCoords.y - currentScheme.midtone.y;
  } else {
    const opposite = id === "first" ? "second" : "first";
    newColors[opposite].x = handleColorPickerOutOfBounds(
      newColors[opposite].x - (newCoords.x - currentScheme[id].x),
      pickerWidth
    );
    newColors[opposite].y -= newCoords.y - currentScheme[id].y;
  }
  return newColors;
};
