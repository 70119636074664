import { selectColorValueScheme } from "components/ColorPicker/state/selectors";
import { useSelector } from "react-redux";
import { ColorPicker } from "../ColorPicker/ColorPicker";
import { ColorPickerWrapper } from "../ColorPicker/ColorPickerWrapper/ColorPickerWrapper";
import { ColorPaletteCard } from "components/ColorPalette/ColorPaletteCard";
import { Gallery } from "components/Gallery/Gallery";
import { Container, Paper, Stack } from "@mui/material";
export const Main: React.FC = () => {
  const colorScheme = useSelector(selectColorValueScheme);
  return (
    <Container>
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        <Paper sx={{ width: "90%" }}>
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
          >
            <ColorPickerWrapper>
              <ColorPicker />
            </ColorPickerWrapper>
            <ColorPaletteCard colors={colorScheme} active />
          </Stack>
        </Paper>
        <Paper sx={{ width: "90%" }}>
          <Gallery />
        </Paper>
      </Stack>
    </Container>
  );
};
