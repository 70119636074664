export interface Color {
  hex: string;
  rgb: ColorRGB;
  hsv: ColorHSV;
}

export interface ColorRGB {
  r: number;
  g: number;
  b: number;
}

export interface ColorHSV {
  h: number;
  s: number;
  v: number;
}

export enum ColorSchemeNames {
  "HIGHLIGHT" = "HIGHLIGHT",
  "MIDTONE" = "MIDTONE",
  "SHADOW" = "SHADOW",
}

export enum ItemTypes {
  "COLORRANGE" = "COLORRANGE",
  "COLORSELECTOR" = "COLORSELECTOR",
}
export interface DragItem {
  type: string;
  id: string;
  y: number;
  x: number;
}
export interface ColorValueScheme {
  first: Color;
  midtone: Color;
  second: Color;
}
