import { IRootState } from "state/types";
import { Palette } from "./types";

export const selectAllPalettes = (state: IRootState): Palette[] =>
  state.gallery.palettes;

export const selectPaletteByIndex = (
  state: IRootState,
  index: number
): Palette => state.gallery.palettes[index];
