import { useDispatch, useSelector } from "react-redux";
import { clamp, rgbToHex } from "utils";
import "./SaturationSlider.css";
import { selectSaturation, selectColorValue } from "../state/selectors";
import { setSaturation } from "../state/actions";
import { IRootState } from "state/types";
import { useCallback } from "react";

export const SaturationSlider = () => {
  const dispatch = useDispatch();
  const saturation = useSelector(selectSaturation);
  const midtoneColor = useSelector((state: IRootState) =>
    selectColorValue(state, "midtone")
  );
  const handleSaturationChange = useCallback(
    (event: any) => {
      const { width, left } = event.target.getBoundingClientRect();
      const x = clamp(event.clientX - left, 0, width);
      const s = (x / width) * 100;
      dispatch(setSaturation(s));
    },
    [dispatch]
  );
  return (
    <div
      className="cp-saturation"
      onClick={handleSaturationChange}
      style={{
        backgroundColor: rgbToHex(midtoneColor.rgb),
      }}
    >
      <div
        className="cp-saturation-indicator"
        style={{
          backgroundColor: "transparent",
          left: (saturation ?? 0) + "%",
        }}
      />
    </div>
  );
};
