import { createAction } from "typesafe-actions";
import { ColorCoords, ColorScheme } from "./types";

export const setColorScheme = createAction(
  "colorPicker/SET_COLOR_SCHEME"
)<ColorScheme>();

export const setColorInScheme = createAction(
  "colorPicker/SET_COLOR_IN_SCHEME",
  (newCoords: ColorCoords, whichColor: string) => ({ whichColor, newCoords })
)<{ whichColor: string; newCoords: ColorCoords }>();

export const setHueOffset = createAction(
  "colorPicker/SET_HUE_OFFSET"
)<number>();

export const setPickerWidth = createAction(
  "colorPicker/SET_PICKER_WIDTH"
)<number>();
export const setPickerHeight = createAction(
  "colorPicker/SET_PICKER_HEIGHT"
)<number>();
export const setPickerTop = createAction(
  "colorPicker/SET_PICKER_TOP"
)<number>();

export const setSaturation = createAction(
  "colorPicker/SET_SATURATION"
)<number>();
