import {
  Card,
  CardHeader,
  CardContent,
  Avatar,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { Edit, CheckCircle, Cancel } from "@mui/icons-material";
import { Color, ColorValueScheme } from "types/Color";
import { ColorPalette } from "./ColorPalette";
import { useRef, useState } from "react";
import { OptionsMenu } from "./OptionsMenu/OptionsMenu";
import { exportComponentAsPNG } from "react-component-export-image";
import { useDispatch } from "react-redux";
import { Palette } from "components/Gallery/state/types";
import { addPaletteToGallery } from "components/Gallery/state/actions";

interface ColorPaletteCardProps {
  colors: ColorValueScheme;
  title?: string;
  active?: boolean;
}

export const ColorPaletteCard = (props: ColorPaletteCardProps) => {
  const dispatch = useDispatch();
  const colorsSorted: Color[] = Object.values(props.colors).sort((a, b) => {
    return a.hsv.v - b.hsv.v;
  });
  const [editing, setEditing] = useState<boolean>(false);
  const [titleValue, setTitleValue] = useState<string>(
    props.title ? props.title : "New Color Scheme"
  );
  const [editingTitleValue, setEditingTitleValue] =
    useState<string>(titleValue);
  const printRef = useRef(document.createElement("div"));
  const handleEditButtonClick = () => {
    setEditing(true);
  };
  const handleSaveButtonClick = () => {
    setTitleValue(editingTitleValue);
    setEditing(false);
  };
  const handleCancelButtonClick = () => {
    setEditingTitleValue(titleValue);
    setEditing(false);
  };
  const handleInputTextChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEditingTitleValue(event.target.value);
  };
  const handleExportToPngClick = () => {
    exportComponentAsPNG(printRef, { fileName: `${titleValue}.png` });
  };
  const handleSaveToGalleryClick = () => {
    const newPalette: Palette = {
      colorScheme: props.colors,
      title: titleValue,
    };
    dispatch(addPaletteToGallery(newPalette));
  };
  return (
    <Card sx={{ maxWidth: "95%" }}>
      <CardHeader
        avatar={
          <Avatar
            sx={{
              background: `
                radial-gradient(
                    circle at 67% 33%,
                    ${colorsSorted[2].hex},
                    ${colorsSorted[1].hex},
                    ${colorsSorted[0].hex}
                )`,
            }}
          >
            <div />
          </Avatar>
        }
        action={
          <div>
            {props.active && (
              <IconButton
                onClick={
                  editing ? handleSaveButtonClick : handleEditButtonClick
                }
              >
                {editing ? <CheckCircle /> : <Edit />}
              </IconButton>
            )}
            {props.active && editing ? (
              <IconButton onClick={handleCancelButtonClick}>
                <Cancel />
              </IconButton>
            ) : null}
            <OptionsMenu
              handleExportClick={handleExportToPngClick}
              handleSaveToGallery={handleSaveToGalleryClick}
              exportDisabled={!props.active}
            />
          </div>
        }
        title={
          editing ? (
            <TextField
              variant="standard"
              label="Color Scheme Name"
              value={editingTitleValue}
              onChange={handleInputTextChange}
            />
          ) : (
            <Typography>{props.active ? titleValue : props.title}</Typography>
          )
        }
      />
      <CardContent ref={printRef}>
        <ColorPalette colorsSorted={colorsSorted} />
      </CardContent>
    </Card>
  );
};
