import { IconButton, Menu, MenuItem } from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import { useState } from "react";

interface IOptionsMenuProps {
  handleExportClick: () => void;
  handleSaveToGallery: () => void;
  exportDisabled?: boolean;
}

export const OptionsMenu: React.FC<IOptionsMenuProps> = ({
  handleExportClick,
  handleSaveToGallery,
  exportDisabled = false,
}: IOptionsMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleExport = () => {
    setAnchorEl(null);
    handleExportClick();
  };
  const handlePassToGallery = () => {
    setAnchorEl(null);
    handleSaveToGallery();
  };

  return (
    <>
      <IconButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleExport}>Save as PNG</MenuItem>
        {!exportDisabled && (
          <MenuItem onClick={handlePassToGallery}>Save to Gallery</MenuItem>
        )}
      </Menu>
    </>
  );
};
