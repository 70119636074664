import { Color, ColorRGB } from "../types/Color";
import { ColorCoords } from "components/ColorPicker/state/types";
import { hexToRgb, hsvToRgb, rgbToHex, rgbToHsv } from "./Converter";
import { selectPickerHeight } from "components/ColorPicker/state/selectors";
import { store } from "store";

export function getRgb(color: string): ColorRGB {
  const matches = /rgb\((\d+),\s?(\d+),\s?(\d+)\)/i.exec(color);
  const r = Number(matches?.[1] ?? 0);
  const g = Number(matches?.[2] ?? 0);
  const b = Number(matches?.[3] ?? 0);

  return {
    r,
    g,
    b,
  };
}
export const avoidX = (x: number, avoid: number) => {
  const diff = x - avoid;
  if (Math.abs(diff) < 5) {
    return avoid + (5 * diff) / Math.abs(diff);
  } else {
    return x;
  }
};
export const avoidY = (y: number, avoid: number) => {
  const diff = y - avoid;
  if (Math.abs(diff) < 5) {
    return avoid + (5 * diff) / Math.abs(diff);
  } else {
    return y;
  }
};

export function parseColor(color: string): Color {
  var hex = "";
  var rgb = {
    r: 0,
    g: 0,
    b: 0,
  };
  var hsv = {
    h: 0,
    s: 0,
    v: 0,
  };

  if (color.slice(0, 1) === "#") {
    hex = color;
    rgb = hexToRgb(hex);
    hsv = rgbToHsv(rgb);
  } else if (color.slice(0, 3) === "rgb") {
    rgb = getRgb(color);
    hex = rgbToHex(rgb);
    hsv = rgbToHsv(rgb);
  }

  return {
    hex,
    rgb,
    hsv,
  };
}

export function getHueCoordinates(
  color: Color,
  width: number,
  offset?: number
): ColorCoords {
  const state = store.getState();
  const height = selectPickerHeight(state);
  const { h, v } = color.hsv;
  const adj = offset ? offset : 0;
  const x = ((h - adj) / 360) * (width * 0.75);
  const y = (100 - v) * Math.round(height / 100);
  // 100-v = y * 100 / height
  //-v = (100y/height)-100
  //v = 100-(100y/height)
  // v = 100 * (1-(y/height))
  return { x, y };
}

export const getColorFromCoordsWithHueOffset = (
  color: ColorCoords,
  hueStart: number,
  saturation: number,
  width: number
): Color => {
  const state = store.getState();
  const height = selectPickerHeight(state);
  const { x, y } = color;
  const h = Math.round((x / width) * 360) + hueStart;
  const v = Math.round(100 * (1 - y / height));
  const rgb = hsvToRgb({ h, s: saturation, v });
  const hex = rgbToHex(rgb);
  let retColor: Color = {
    hsv: { h, s: saturation, v },
    rgb: rgb,
    hex: hex,
  };
  return retColor;
};

export const colorFromCoords = (
  coords: ColorCoords,
  saturation: number,
  width: number
): Color => {
  const { x, y } = coords;
  const h = Math.round((x / width) * 360);
  const v = Math.round(100 - y / 4);
  const rgb = hsvToRgb({ h, s: saturation, v });
  const hex = rgbToHex(rgb);
  let retColor = parseColor(hex);
  retColor.hsv = { h, s: saturation, v };
  retColor.rgb = rgb;
  return retColor;
};

export function getSaturationCoordinates(color: Color): number {
  const { s } = color.hsv;

  const x = s;

  return x;
}

export function clamp(number: number, min: number, max: number): number {
  if (!max) {
    return Math.max(number, min) === min ? number : min;
  } else if (Math.min(number, min) === number) {
    return min;
  } else if (Math.max(number, max) === number) {
    return max;
  }
  return number;
}

export const clipTop = (y: number, height: number) => {
  return y > height ? height : y < 0 ? 0 : y;
};

const hslStringFromHue = (hue: number): string => {
  const hueAdj = hue > 360 ? hue - 360 : hue < 0 ? 360 + hue : hue;
  return `hsl(${hueAdj},100%,50%)`;
};

export const generateHueRangeFromStart = (hueStart: number): string[] => {
  let retArr = [];
  for (let i = 0; i <= 6; i++) {
    retArr[i] = hslStringFromHue(hueStart + i * 60);
  }
  return retArr;
};
