import { getDefaultColorscheme } from "./utils";
import { IColorPickerState } from "./types";
import * as ColorPickerActions from "./actions";
import { ActionType, getType } from "typesafe-actions";
import { moveSinglePicker } from "./reducerHelper";

export const initialState: IColorPickerState = {
  colorScheme: getDefaultColorscheme(window.innerWidth),
  hueStart: 0,
  pickerWidth: 50,
  pickerHeight: 400,
  pickerTop: 10,
  saturation: 100,
};

export const colorPickerReducer = (
  state: IColorPickerState = initialState,
  action: ActionType<typeof ColorPickerActions>
): IColorPickerState => {
  switch (action.type) {
    case getType(ColorPickerActions.setPickerWidth):
      return { ...state, pickerWidth: action.payload };
    case getType(ColorPickerActions.setPickerHeight):
      return { ...state, pickerHeight: action.payload };
    case getType(ColorPickerActions.setPickerTop):
      return { ...state, pickerTop: action.payload };
    case getType(ColorPickerActions.setColorScheme):
      return { ...state, colorScheme: action.payload };
    case getType(ColorPickerActions.setColorInScheme):
      return {
        ...state,
        colorScheme: moveSinglePicker(
          action.payload.whichColor,
          action.payload.newCoords,
          state.colorScheme,
          state.pickerWidth
        ),
      };
    case getType(ColorPickerActions.setHueOffset):
      return {
        ...state,
        hueStart: action.payload,
      };
    case getType(ColorPickerActions.setSaturation):
      return {
        ...state,
        saturation: action.payload,
      };
    default:
      return { ...state };
  }
};
