import { ImageList, ImageListItem } from "@mui/material";

import { ColorPaletteCard } from "components/ColorPalette/ColorPaletteCard";
import { useSelector } from "react-redux";
import { selectAllPalettes } from "./state/selectors";
import { Palette } from "./state/types";

export const Gallery = () => {
  const palettes: Palette[] = useSelector(selectAllPalettes);

  return (
    <ImageList sx={{ width: "100%" }} cols={2}>
      {palettes.map((palette: Palette, index: number) => {
        return (
          <ImageListItem key={index}>
            <ColorPaletteCard
              colors={palette.colorScheme}
              active={false}
              title={palette.title}
            />
          </ImageListItem>
        );
      })}
    </ImageList>
  );
};
